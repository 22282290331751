import { graphql } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { useScript } from '../../../hooks/useScript';
import { usePrivacyConsent, usePrivacyModal } from '../../../hooks/usePrivacy';
import { Button } from '../../common/button';
import { Content } from '../../common/content';
import './infographic.scss';

const bem = new BEMHelper({
  name: 'content-infographic',
});

const INFOGRAM_SCRIPT_ID = 'infogram-async';
const INFOGRAM_SCRIPT_URL = 'https://e.infogram.com/js/dist/embed-loader-min.js';
const INFOGRAM_GLOBAL = 'InfogramEmbeds';

const InfogramEmbed = ({ infogramId }) => {
  useScript(
    INFOGRAM_SCRIPT_URL,
    () => window[INFOGRAM_GLOBAL]?.initialized && window[INFOGRAM_GLOBAL]?.process,
    () => window[INFOGRAM_GLOBAL].process(),
    { id: INFOGRAM_SCRIPT_ID },
  );

  return (
    <div
      className="infogram-embed"
      data-id={infogramId}
      data-type="interactive"
    />
  );
};

InfogramEmbed.propTypes = {
  infogramId: string.isRequired,
};

export const Infographic = ({
  id,
  infogramId,
  caption,
  footnotes,
  appearance,
}) => {
  const { t } = useTranslation();
  const [ref, isVisible] = useIntersectionObserver({ triggerOnce: true });
  const canUseContents = usePrivacyConsent('contents');
  const { showModal } = usePrivacyModal();

  let alignment = appearance;
  if (!caption) {
    // If an infographic is served without a caption, the graphic will always be
    // rendered as a fullsize aligned infographic...
    alignment = 'fullsize';
  }

  return (
    <Content id={id} {...bem()}>
      <figure {...bem('figure')}>
        <div
          {...bem('viewport', {
            [`alignment-${alignment}`]: true,
            'no-consent': !canUseContents,
          })}
          ref={ref}
        >
          {canUseContents
            ? isVisible && (<InfogramEmbed infogramId={infogramId} />)
            : (
              <div {...bem('no-consent')}>
                <p {...bem('no-consent__text')}>
                  {t('Due to your current cookie consent settings, we cannot display the infographic here. Please change the settings accordingly or open the infographic on an external page.')}
                </p>
                <Button
                  {...bem('no-consent__button')}
                  variant="primary"
                  title={t('Click here to change the cookie settings')}
                  onClick={showModal}
                >
                  {t('Change settings')}
                </Button>
                <Button
                  {...bem('no-consent__button')}
                  variant="secondary"
                  href={`https://infogram.com/${infogramId}`}
                  target="_blank"
                  title=""
                >
                  {t('Open infogram.com')}
                </Button>
              </div>
            )}
        </div>
        {caption && (
          <figcaption {...bem('caption', `alignment-${alignment}`)}>
            {parse(caption)}
            {footnotes && (
              <footer {...bem('footnotes')}>
                {parse(footnotes)}
              </footer>
            )}
          </figcaption>
        )}
      </figure>
    </Content>
  );
};

Infographic.propTypes = {
  id: string.isRequired,
  infogramId: string.isRequired,
  caption: string,
  footnotes: string,
  appearance: string,
};

Infographic.defaultProps = {
  caption: undefined,
  footnotes: undefined,
  appearance: 'fullsize',
};

export const fragment = graphql`
  fragment InfographicContentFragment on CraftGQL_pageContents_infographic_BlockType {
    id
    infogramId
    caption
    footnotes
    appearance
  }
`;
