import { canUseDOM } from 'exenv';
import { useEffect } from 'react';

const registry = {};

export const useScript = (src, test, callback = () => undefined, attrs = {}) => useEffect(() => {
  if (!canUseDOM) {
    return;
  }

  if (test()) {
    callback();
    return;
  }

  const found = registry[src];
  if (found) {
    if (found.length > 0) {
      // Script is already added to head, but not fully loaded. Add the current
      // callback to the list of callbacks and wait until script is loaded..
      found.push(callback);
    } else {
      // Script is already loaded and all callbacks are executed. We can execute
      // this callback instantly...
      callback();
    }

    return;
  }

  const doc = document;
  const script = doc.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = src;
  Object.entries(attrs).forEach(([key, value]) => {
    script[key] = value;
  });

  registry[src] = [callback];
  script.onload = () => {
    // Resolve all registered callbacks from oldest -> latest.
    while (registry[src].length) {
      (registry[src].shift())();
    }
  };

  doc.head.appendChild(script);
}, []);
